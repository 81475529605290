<template>
    <div style="padding: 0 20px">
        <el-form :model="addForm" ref="addForm" class="demo-ruleForm" :hide-required-asterisk="true">
            <div class="bit-center-blk">
              <div class="big-title">省份分布</div>
              <div class="big-blk-import">
                  <el-button size="mini" @click="downloadBuyer">下载卖家分析数据模板</el-button>
                  <el-button size="mini" type="primary" @click="importBuyer">导入卖家分析数据</el-button>
                  <input type="file" name="buyerdata" id="buyerdata" ref="buyerdata" class="buyerdata" @change="importBuyerEvt">
              </div>
            </div>
            <div class="form-item-1 flex">
                <div class="item-left-first" style="margin-bottom: 0"></div>
                <div class="item-right">
                    <div class="item-text">店铺数</div>
                    <div class="item-text">动销店铺数</div>
                    <div class="item-text">成交金额</div>
                </div>
            </div>
            <div class="form-item-1 flex" v-for="(regionItem, regionIndex) in addForm.provinces_distribution_data" :key="`provinces_${regionIndex}`">
                <div class="item-left-box">
                    <div class="item-left-first">{{regionItem.region_code | filterProvinces}}</div>
                    <div class="item-left-first">波动范围</div>
                </div>
                <div class="item-right">
                    <div class="item-text">
                        <el-form-item :prop="`provinces_distribution_data.${regionIndex}.stores_number`"
                                      :rules="{required: true, message: '店铺数不能为空', trigger: 'blur'}">
                            <el-input-number class="number-input1" v-model="regionItem.stores_number"
                                             :ref="`provinces_distribution_data_${regionIndex}_stores_number`" :min="1" :max="999999999" :precision="0" :controls="false"></el-input-number>
                        </el-form-item>
                        <div class="flex">
                            <el-form-item :prop="`provinces_distribution_data.${regionIndex}.stores_number_range_min`"
                                          :rules="{required: true, message: '低值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="regionItem.stores_number_range_min" :ref="`provinces_distribution_data_${regionIndex}_stores_number_range_min`"
                                                 :min="-99" :max="regionItem.stores_number_range_max ? regionItem.stores_number_range_max - 1 : 99" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                            <span class="prompt-text" style="margin-right: 4px">-</span>
                            <el-form-item :prop="`provinces_distribution_data.${regionIndex}.stores_number_range_max`"
                                          :rules="{required: true, message: '高值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="regionItem.stores_number_range_max" :ref="`provinces_distribution_data_${regionIndex}_stores_number_range_max`"
                                                 :min="regionItem.stores_number_range_min ? regionItem.stores_number_range_min + 1 : -98" :max="100" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                        </div>
                    </div>
                    <div class="item-text">
                        <el-form-item :prop="`provinces_distribution_data.${regionIndex}.sale_stores_number`"
                                      :rules="{required: true, message: '动销店铺数不能为空', trigger: 'blur'}">
                            <el-input-number class="number-input1" v-model="regionItem.sale_stores_number"
                                             :ref="`provinces_distribution_data_${regionIndex}_sale_stores_number`" :min="1" :max="999999999" :precision="0" :controls="false"></el-input-number>
                        </el-form-item>
                        <div class="flex">
                            <el-form-item :prop="`provinces_distribution_data.${regionIndex}.sale_stores_number_range_min`"
                                          :rules="{required: true, message: '低值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="regionItem.sale_stores_number_range_min" :ref="`provinces_distribution_data_${regionIndex}_sale_stores_number_range_min`"
                                                 :min="-99" :max="regionItem.sale_stores_number_range_max ? regionItem.sale_stores_number_range_max - 1 : 99" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                            <span class="prompt-text" style="margin-right: 4px">-</span>
                            <el-form-item :prop="`provinces_distribution_data.${regionIndex}.sale_stores_number_range_max`"
                                          :rules="{required: true, message: '高值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="regionItem.sale_stores_number_range_max" :ref="`provinces_distribution_data_${regionIndex}_sale_stores_number_range_max`"
                                                 :min="regionItem.sale_stores_number_range_min ? regionItem.sale_stores_number_range_min + 1 : -98" :max="100" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                        </div>
                    </div>
                    <div class="item-text">
                        <el-form-item :prop="`provinces_distribution_data.${regionIndex}.trading_money`"
                                      :rules="{required: true, message: '成交金额不能为空', trigger: 'blur'}">
                            <el-input-number class="number-input1" v-model="regionItem.trading_money" :min="0.01" :max="999999999.99" :precision="2" :controls="false"></el-input-number>
                        </el-form-item>
                        <div class="flex">
                            <el-form-item :prop="`provinces_distribution_data.${regionIndex}.trading_money_range_min`"
                                          :rules="{required: true, message: '低值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="regionItem.trading_money_range_min"
                                                 :min="-99" :max="regionItem.trading_money_range_max ? regionItem.trading_money_range_max - 1 : 99" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                            <span class="prompt-text" style="margin-right: 4px">-</span>
                            <el-form-item :prop="`provinces_distribution_data.${regionIndex}.trading_money_range_max`"
                                          :rules="{required: true, message: '高值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="regionItem.trading_money_range_max"
                                                 :min="regionItem.trading_money_range_min ? regionItem.trading_money_range_min + 1 : -98" :max="100" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="big-title">类目分布</div>
            <div class="form-item-2">
                <div class="item-text" style="margin-right: 40px;">类目名称</div>
                <div class="item-text" style="margin-right: 40px">店铺数</div>
                <div class="item-text" style="margin-right: 40px">动销店铺数</div>
                <div class="item-text" style="margin-right: 40px">成交金额</div>
                <div class="item-text" style="margin-right: 40px">成交单量</div>
            </div>
            <div class="form-item-2" v-for="(categoryItem, categoryIndex) in addForm.category_distribution_data" :key="`category_${categoryIndex}`">
                <div class="item-box">
                    <el-form-item class="item-input"
                                  :prop="`category_distribution_data.${categoryIndex}.category_name`"
                                  :rules="{required: true, message: '类目名称不能为空', trigger: 'blur'}">
                        <el-input v-model.trim="categoryItem.category_name" :ref="`category_distribution_data_${categoryIndex}_category_name`"
                                  placeholder="请输入" maxlength="12" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="item-box">
                    <el-form-item class="item-input"
                                  :prop="`category_distribution_data.${categoryIndex}.stores_number`"
                                  :rules="{required: true, message: '店铺数不能为空', trigger: 'blur'}">
                        <el-input-number class="number-input1" v-model="categoryItem.stores_number" :ref="`category_distribution_data_${categoryIndex}_stores_number`"
                                         :min="categoryItem.sale_stores_number ? categoryItem.sale_stores_number : 1" :max="999999999" :precision="0" :controls="false"></el-input-number>
                    </el-form-item>
                </div>
                <div class="item-box">
                    <el-form-item class="item-input"
                                  :prop="`category_distribution_data.${categoryIndex}.sale_stores_number`"
                                  :rules="{required: true, message: '动销店铺数不能为空', trigger: 'blur'}">
                        <el-input-number class="number-input1" v-model="categoryItem.sale_stores_number" :ref="`category_distribution_data_${categoryIndex}_sale_stores_number`"
                                         :min="1" :max="categoryItem.stores_number ? categoryItem.stores_number : 999999999" :precision="0" :controls="false"></el-input-number>
                    </el-form-item>
                </div>
                <div class="item-box">
                    <el-form-item class="item-input"
                                  :prop="`category_distribution_data.${categoryIndex}.trading_money`"
                                  :rules="{required: true, message: '成交金额不能为空', trigger: 'blur'}">
                        <el-input-number class="number-input1" v-model="categoryItem.trading_money" :ref="`category_distribution_data_${categoryIndex}_trading_money`"
                                         :min="0.01" :max="999999999.99" :precision="2" :controls="false"></el-input-number>
                    </el-form-item>
                </div>
                <div class="item-box">
                    <el-form-item class="item-input"
                                  :prop="`category_distribution_data.${categoryIndex}.trading_order`"
                                  :rules="{required: true, message: '成交单量不能为空', trigger: 'blur'}">
                        <el-input-number class="number-input1" v-model="categoryItem.trading_order" :ref="`category_distribution_data_${categoryIndex}_trading_order`"
                                         :min="1" :max="999999999" :precision="0" :controls="false"></el-input-number>
                    </el-form-item>
                </div>
            </div>

            <div class="big-title">商品上新时间分布</div>
            <div class="form-item-1 flex">
                <div class="item-left-first" style="margin-bottom: 0"></div>
                <div class="item-right">
                    <div class="item-text">1~3月</div>
                    <div class="item-text">4~6月</div>
                    <div class="item-text">7~9月</div>
                    <div class="item-text">10~12月</div>
                </div>
            </div>
            <div class="form-item-1 flex">
                <div class="item-left-box">
                    <div class="item-left-first">上新商品数</div>
                    <div class="item-left-first">波动范围</div>
                </div>
                <div class="item-right">
                    <div class="item-text" v-for="(goodItem, goodIndex) in addForm.goodupdate_times_distribution_data" :key="`goodupdate_${goodIndex}`">
                        <el-form-item :prop="`goodupdate_times_distribution_data.${goodIndex}.good_number`"
                                      :rules="{required: true, message: '上新商品数不能为空', trigger: 'blur'}">
                            <el-input-number class="number-input1" v-model="goodItem.good_number" :min="1" :max="999999999" :precision="0" :controls="false"></el-input-number>
                        </el-form-item>
                        <div class="flex">
                            <el-form-item :prop="`goodupdate_times_distribution_data.${goodIndex}.good_number_range_min`"
                                          :rules="{required: true, message: '低值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="goodItem.good_number_range_min"
                                                 :min="-99" :max="goodItem.good_number_range_max ? goodItem.good_number_range_max - 1 : 99" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                            <span class="prompt-text" style="margin-right: 4px">-</span>
                            <el-form-item :prop="`goodupdate_times_distribution_data.${goodIndex}.good_number_range_max`"
                                          :rules="{required: true, message: '高值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="goodItem.good_number_range_max"
                                                 :min="goodItem.good_number_range_min ? goodItem.good_number_range_min + 1 : -98" :max="100" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>

        <div style="text-align: center; margin: 40px 0">
            <el-button @click="goBack">上一步</el-button>
            <el-button type="primary" @click="submitForm('addForm')">下一步</el-button>
        </div>
    </div>
</template>

<script>
    import { dataCentorTplGetRegion, dataCentorTplAddSellerAnalysis,
      dataCentorTplGetSellerAnalysis, downloadBuyerData, importBuyerData } from '@/utils/apis.js'
    export default {
        filters: {
            filterProvinces(val) {
                let obj = {
                    1001: '华北地区',
                    1002: '华中地区',
                    1003: '华东地区',
                    1004: '华南地区',
                    1005: '东北地区',
                    1006: '西北地区',
                    1007: '西南地区',
                }
                return obj[val]
            },
        },
        data() {
            return {
                addForm: {
                    provinces_distribution_data: [], // 省份分布
                    category_distribution_data: [ // 类目分布
                        {
                            category_name: '',
                            stores_number: void 0,
                            sale_stores_number: void 0,
                            trading_money: void 0,
                            trading_order: void 0,
                        },
                        {
                            category_name: '',
                            stores_number: void 0,
                            sale_stores_number: void 0,
                            trading_money: void 0,
                            trading_order: void 0,
                        },
                        {
                            category_name: '',
                            stores_number: void 0,
                            sale_stores_number: void 0,
                            trading_money: void 0,
                            trading_order: void 0,
                        },
                        {
                            category_name: '',
                            stores_number: void 0,
                            sale_stores_number: void 0,
                            trading_money: void 0,
                            trading_order: void 0,
                        },
                        {
                            category_name: '',
                            stores_number: void 0,
                            sale_stores_number: void 0,
                            trading_money: void 0,
                            trading_order: void 0,
                        },
                        {
                            category_name: '',
                            stores_number: void 0,
                            sale_stores_number: void 0,
                            trading_money: void 0,
                            trading_order: void 0,
                        },
                        {
                            category_name: '',
                            stores_number: void 0,
                            sale_stores_number: void 0,
                            trading_money: void 0,
                            trading_order: void 0,
                        },
                        {
                            category_name: '',
                            stores_number: void 0,
                            sale_stores_number: void 0,
                            trading_money: void 0,
                            trading_order: void 0,
                        },
                        {
                            category_name: '',
                            stores_number: void 0,
                            sale_stores_number: void 0,
                            trading_money: void 0,
                            trading_order: void 0,
                        },
                        {
                            category_name: '',
                            stores_number: void 0,
                            sale_stores_number: void 0,
                            trading_money: void 0,
                            trading_order: void 0,
                        },
                    ],
                    goodupdate_times_distribution_data: [ // 商品上新时间分布
                        {
                            good_number: void 0,
                            good_number_range_min: void 0,
                            good_number_range_max: void 0,
                        },
                        {
                            good_number: void 0,
                            good_number_range_min: void 0,
                            good_number_range_max: void 0,
                        },
                        {
                            good_number: void 0,
                            good_number_range_min: void 0,
                            good_number_range_max: void 0,
                        },
                        {
                            good_number: void 0,
                            good_number_range_min: void 0,
                            good_number_range_max: void 0,
                        },
                    ]
                }
            }
        },
        mounted() {
            this.getProvincesList()
        },
        methods: {
            downloadBuyer() {
                downloadBuyerData().then(res => {
                    window.open(localStorage.getItem('hosturl') + res.data.url, '_blank');
                }).catch(err => {
                    console.log(err);
                })
            },
            importBuyer() {
                this.$refs.buyerdata.click();
            },
            importBuyerEvt() {
                let formdata = new FormData();
                let file = this.$refs.buyerdata.files[0];
                formdata.append('excel', file);
                importBuyerData(formdata).then(res => {
                    res.data.province_data.map((item, index) => {
                        this.addForm.provinces_distribution_data[index].stores_number =  item[0];
                        this.addForm.provinces_distribution_data[index].stores_number_range_min =  item[1];
                        this.addForm.provinces_distribution_data[index].stores_number_range_max =  item[2];
                        this.addForm.provinces_distribution_data[index].sale_stores_number =  item[3];
                        this.addForm.provinces_distribution_data[index].sale_stores_number_range_min =  item[4];
                        this.addForm.provinces_distribution_data[index].sale_stores_number_range_max =  item[5];
                        this.addForm.provinces_distribution_data[index].trading_money =  item[6];
                        this.addForm.provinces_distribution_data[index].trading_money_range_min =  item[7];
                        this.addForm.provinces_distribution_data[index].trading_money_range_max =  item[8];
                    });
                    res.data.category_data.map((item, index) => {
                        this.addForm.category_distribution_data[index].category_name = item[0];
                        this.addForm.category_distribution_data[index].stores_number = item[1];
                        this.addForm.category_distribution_data[index].sale_stores_number = item[2];
                        this.addForm.category_distribution_data[index].trading_money = item[3];
                        this.addForm.category_distribution_data[index].trading_order = item[4];
                    });
                    res.data.month_data.map((item, index) => {
                        this.addForm.goodupdate_times_distribution_data[index].good_number = item[0];
                        this.addForm.goodupdate_times_distribution_data[index].good_number_range_min = item[1];
                        this.addForm.goodupdate_times_distribution_data[index].good_number_range_max = item[2];
                    });
                }).catch(err => {
                  console.log('err', err);
                });
                this.$refs.buyerdata.value = '';
            },
            // 获取详情
            getInfo() {
                let params = {
                    data_center_tpl_id: this.$route.query.data_center_tpl_id
                }
                dataCentorTplGetSellerAnalysis(params).then((res) => {
                    this.addForm = res.data
                }).catch((err) => {})
            },
            // 获取省份分布
            getProvincesList() {
                dataCentorTplGetRegion().then((res) => {
                    this.addForm.provinces_distribution_data = res.data.map(item => {
                        return {
                            region_code: item.region_code,
                            stores_number: void 0,
                            stores_number_range_min: void 0,
                            stores_number_range_max: void 0,
                            sale_stores_number: void 0,
                            sale_stores_number_range_min: void 0,
                            sale_stores_number_range_max: void 0,
                            trading_money: void 0,
                            trading_money_range_min: void 0,
                            trading_money_range_max: void 0,
                        }
                    })
                    this.getInfo()
                }).catch((err) => {})
            },
            // 上一步
            goBack() {
                this.$router.push({
                    path: '/practice/dataStepFour',
                    query: {
                        stepActive: 4,
                        goodClassId: Number(this.$route.query.goodClassId),
                        data_center_tpl_id: Number(this.$route.query.data_center_tpl_id),
                    }
                })
            },
            // 下一步
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // 判断：店铺数*店铺数的最小波动范围  >= 动销店铺数*动销店铺数的最大波动范围
                        this.addForm.provinces_distribution_data.forEach((item, index) => {
                            for (const key in item) {
                                if (key === 'stores_number' || key === 'stores_number_range_min' || key === 'stores_number_range_max' ||
                                    key === 'sale_stores_number' || key === 'sale_stores_number_range_min' || key === 'sale_stores_number_range_max') {
                                    if (Number(item.stores_number) * (1 + Number(item.stores_number_range_min) * 0.01) < Number(item.sale_stores_number) * (1 + Number(item.sale_stores_number_range_max) * 0.01)) {
                                        this.$refs[`provinces_distribution_data_${index}_${key}`][0].$el.classList.add('isError')
                                    } else {
                                        this.$refs[`provinces_distribution_data_${index}_${key}`][0].$el.classList.remove('isError')
                                    }
                                }

                            }
                        })
                        for (let i = 0; i < this.addForm.provinces_distribution_data.length; i++) {
                            let item = this.addForm.provinces_distribution_data[i]
                            if (Number(item.stores_number) * (1 + Number(item.stores_number_range_min) * 0.01) < Number(item.sale_stores_number) * (1 + Number(item.sale_stores_number_range_max) * 0.01)) {
                                return this.$message.warning('店铺数*(1 + 店铺数的最小波动范围)  >= 动销店铺数*(1 + 动销店铺数的最大波动范围)')
                            }
                        }

                        // 判断类目名称是否重复
                        let result = false;
                        outer: for (let i = 0; i < this.addForm.category_distribution_data.length - 1; i++) {
                            for (let j = i + 1; j < this.addForm.category_distribution_data.length; j++) {
                                if (this.addForm.category_distribution_data[i].category_name === this.addForm.category_distribution_data[j].category_name) {
                                    result = true
                                    this.$refs[`category_distribution_data_${i}_category_name`][0].$el.classList.add('isError')
                                    this.$refs[`category_distribution_data_${j}_category_name`][0].$el.classList.add('isError')
                                    break outer
                                } else {
                                    this.$refs[`category_distribution_data_${i}_category_name`][0].$el.classList.remove('isError')
                                    this.$refs[`category_distribution_data_${j}_category_name`][0].$el.classList.remove('isError')
                                }
                            }
                        }
                        if (result === true) {
                            this.$message.warning('类目名称不能重复')
                            return
                        }

                        let formData = new FormData()
                        formData.append('data_center_tpl_id', this.$route.query.data_center_tpl_id)
                        formData.append('provinces_distribution_data', JSON.stringify(this.addForm.provinces_distribution_data))
                        formData.append('category_distribution_data', JSON.stringify(this.addForm.category_distribution_data))
                        formData.append('goodupdate_times_distribution_data', JSON.stringify(this.addForm.goodupdate_times_distribution_data))
                        dataCentorTplAddSellerAnalysis(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.$router.push({
                                path: '/practice/dataStepSix',
                                query: {
                                    stepActive: 6,
                                    goodClassId: Number(this.$route.query.goodClassId),
                                    data_center_tpl_id: Number(this.$route.query.data_center_tpl_id),
                                }
                            })
                        }).catch((err) => {})


                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .demo-ruleForm {
        ::v-deep .el-form-item__label {
            font-size: 16px;
            color: #333;
        }
    }
    .bit-center-blk {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .buyerdata {
            display: none;
        }

    }
    .big-title {
        margin: 40px 0;
        font-size: 16px;
        padding-left: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &:before {
            content: '';
            width: 4px;
            height: 16px;
            background: #2DC079;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 2px;
        }
    }
    .form-item-1 {
        font-size: 16px;
        .item-left-box {
            display: flex;
            flex-direction: column;
        }
        .item-left-first {
            width: 110px;
            height: 40px;
            line-height: 40px;
            text-align: right;
            margin-right: 16px;
            margin-bottom: 22px;
        }
        .item-right {
            width: 1%;
            flex: 1;
            display: flex;
        }
        .item-text {
            width: 200px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            margin-right: 40px;
        }
    }
    .form-item-2 {
        display: flex;
        flex-wrap: wrap;
        .item-box {
            margin-right: 40px;
        }
        .item-text {
            width: 182px;
            line-height: 1;
            margin-bottom: 10px;
            text-align: center;
            font-size: 16px;
        }
        .item-input {
            width: 182px;
        }
    }
    .prompt-text {
        font-size: 16px;
        margin-left: 4px;
        color: #999;
        height: 40px;
        line-height: 40px;
    }
    .number-input1 {
        width: 100%;
    }
    ::v-deep .number-input1 {
        &.isError {
            .el-input__inner {
                border-color: #FD4446;
            }
        }
    }
</style>